export async function callApi(endpoint, method = 'GET', data = undefined) {
    const apiUrl = "https://api.broflex.fr/";

    const response = await fetch(`${apiUrl}${endpoint}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""}`,
            },
            body: JSON.stringify(data),
        });
    try {
        return await response.json();
    } catch (e) {
        console.log(e);
        return response.status;
    }
}

export const deepClone = (obj) => {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    const clone = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            clone[key] = deepClone(obj[key]);
        }
    }

    return clone;
};

export const resizeImage = (file, maxWidth, maxHeight, callback) => {
    const img = new Image();

    img.onload = function() {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight){
            const ratio = Math.max(maxWidth / width, maxHeight / height);
            width *= ratio;
            height *= ratio;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(this, 0, 0, width, height);

        canvas.toBlob(function (blob) {
            callback(blob);
        }, 'image/webp', 0.70);
    }

    const reader = new FileReader();
    reader.onload = function(e) {
        img.src = e.target.result;
    };
    reader.readAsDataURL(file);
}