import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {callApi} from "../functions/index.js";

const Category = () => {
    const [categories, setCategories] = useState();
    const nameCat = decodeURI(window.location.pathname.split("/")[1]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await callApi("categories/" + nameCat);
            if (response.error) navigate("/");
            setCategories(response);
        }
        fetchCategories();
    }, [nameCat]);

    return (
        <div className="category">
            <div className="category__header">
                <h1>{nameCat.replace("_", "/")}</h1>
                <p> Vous ne trouvez pas ce que vous voulez ? Essayer une autre catégorie !</p>
            </div>
            <div className="category__content">
                {categories && Object.keys(categories).map((key, index) => {
                    return (
                        <div className="category__content__item" key={index}>
                            <div className="category__content__item__image">
                                <img src={categories[key].img.includes("/images") ? "https://api.broflex.fr" + categories[key].img : categories[key].img } alt={key}
                                     loading="lazy" decoding="async"
                                />
                            </div>
                            <div className="category__content__item__text">
                                <h2>{key.replace("_", "/")}</h2>
                                <NavLink to={"/" + nameCat + "/" + key}>
                                    <button>Voir plus</button>
                                </NavLink>
                                <p>{categories[key].description}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Category;