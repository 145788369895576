import {useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";

export default function ScollToTop({setAdminPath, setButton}) {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        setAdminPath(pathname.includes('admin'));
        setButton(false);
    }, [pathname]);

    return null;
}