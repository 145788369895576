import React from 'react';

const AdminHome = ({data}) => {
    data = {
        ca: 0,
        thismonth: 0,
    }

    return (
        <div className="dashboard">
            <div className="dashboard__title">Tableau de board Broflex</div>
            <div className="dashboard__items">
                <div className="dashboard__content">
                    <div className="dashboard__content__title">Chiffre d'affaire de
                        l'année {new Date().getFullYear()}</div>
                    <div className="dashboard__content__money"> {data.ca} €</div>
                    <div className="dashboard__content__subtitle">dont {data.thismonth}€ ce mois ci</div>
                </div>
                <div className="dashboard__content">
                    <div className="dashboard__content__title">Nombre de vente de
                        l'année {new Date().getFullYear()}</div>
                    <div className="dashboard__content__money"> {data.ca}</div>
                    <div className="dashboard__content__subtitle">dont {data.thismonth} ce mois ci</div>
                </div>
                <div className="dashboard__content">
                    <div className="dashboard__content__title">Nombre de client de
                        l'année {new Date().getFullYear()}</div>
                    <div className="dashboard__content__money"> {data.ca}</div>
                    <div className="dashboard__content__subtitle">dont {data.thismonth} ce mois ci</div>
                </div>
            </div>
        </div>
    );
};

export default AdminHome;