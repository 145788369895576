import {callApi, deepClone, resizeImage} from "../../functions/index.js";
import React, {useEffect, useState} from "react";
import category from "../Category.js";

const AdminPages = () => {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [seeAddSubcat, setSeeAddSubcat] = useState(false);
    const [newSubcat, setNewSubcat] = useState({
        name: "",
        description: "",
        img: "https://via.placeholder.com/300",
        new: true
    });
    const [save, setSave] = useState(true);
    const [pagesInit, setPagesInit] = useState([]);

    const addItem = (type) => {
        if (type === "cat") {
            const name = prompt("Nom de la catégorie");
            if (name) {
                //check if category already exist
                if (pages[name]) return alert("Cette catégorie existe déjà");
                const newPages = deepClone(pages);
                newPages[name] = {};
                setPages(newPages);
                setSelectedCategory(name);
            }
        } else if (type === "subcat") {
            setNewSubcat({name: "", description: "", img: "https://via.placeholder.com/300", new: true})
            setSeeAddSubcat(true);
        }
    }

    const openSelectFile = () => {
        document.getElementById("input__file").click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        if (!file) return;
        resizeImage(file, 500, 500, (blob) => {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                setNewSubcatElement("img", reader.result);
            }
        })
    }

    const setNewSubcatElement = (type, value) => {
        setNewSubcat({...newSubcat, [type]: value});
    }

    const openEditSubcat = (subcat) => {
        const infoSubcat = pages[selectedCategory][subcat];
        setNewSubcat({
            name: subcat,
            description: infoSubcat.description,
            img: infoSubcat.img ? infoSubcat.img : "https://via.placeholder.com/300",
            new: subcat
        });
        setSeeAddSubcat(true);
    }

    const addSubcat = () => {
        const newPages = {...pages};
        const index = Object.keys(newPages).length > 0 ? Object.keys(newPages[selectedCategory])?.indexOf(newSubcat.new) : 0 || 0;
        if (newSubcat.new !== true && newSubcat.new !== newSubcat.name) {
            const newSubcat2 = {}
            for (let i = 0; i < Object.keys(newPages[selectedCategory]).length ; i++) {
                if (i === index) newSubcat2[newSubcat.name] = {description: newSubcat.description, img: newSubcat.img};
                newSubcat2[Object.keys(newPages[selectedCategory])[i]] = newPages[selectedCategory][Object.keys(newPages[selectedCategory])[i]];
            }
            delete newSubcat2[newSubcat.new];
            newPages[selectedCategory] = newSubcat2;
            setPages(newPages);
        } else {

            newPages[selectedCategory][newSubcat.name] = {description: newSubcat.description, img: newSubcat.img};
            setPages(newPages);
        }
        setSeeAddSubcat(false);
        setNewSubcat({name: "", description: "", img: "https://via.placeholder.com/300", new: true});
    }

    const deleteSubcat = (subcat) => {
        const newPages = {...pages};
        // request to user to confirm the deletion with a popup
        const confirm = window.confirm(`Voulez-vous vraiment supprimer la sous-catégorie ${newSubcat.name} de la catégorie ${selectedCategory} ?`);
        if (confirm) {
            delete newPages[selectedCategory][newSubcat.name];
            setPages(newPages);
            setSeeAddSubcat(false);
            setNewSubcat({name: "", description: "", img: "https://via.placeholder.com/300", new: true});
        }
    }

    const deleteCat = () => {
        const newPages = {...pages};
        const confirm = window.confirm(`Voulez-vous vraiment supprimer la catégorie ${selectedCategory} ?`);
        if (confirm) {
            delete newPages[selectedCategory];
            setSelectedCategory("");
            setPages(newPages);
        }
    }

    const changeNameCat = () => {
        const newPages = {...pages};
        const newName = prompt("Nouveau nom de la catégorie");
        if (newName) {
            newPages[newName] = newPages[selectedCategory];
            //get index of selectedCategory
            const index = Object.keys(newPages).indexOf(selectedCategory);
            delete newPages[selectedCategory];
            //move the category to the right place
            const newCategories = {};
            Object.keys(newPages).forEach((key, i) => {
                if (i === index) newCategories[newName] = newPages[selectedCategory];
                newCategories[key] = newPages[key];
            });
            setSelectedCategory(newName);
            setPages(newCategories);
        }
    }

    const handleDragStart = (e, subcategory, type = "sub") => {
        if (type === "cat")
            e.dataTransfer.setData("category", subcategory);
        else
            e.dataTransfer.setData("subcategory", subcategory);
    }

    const handleDragOver = (e, subcategory) => {
        e.preventDefault();
    }

    const handleDrop = (e, targetSubcategory) => {
        e.preventDefault();
        const category = e.dataTransfer.getData("category");
        if (category !== "") {
            const newPages = {...pages};
            const draggedIndex = Object.keys(newPages).indexOf(category);
            const droppedIndex = Object.keys(newPages).indexOf(targetSubcategory);

            const newCategories = {};
            Object.keys(newPages).forEach((key, index) => {
                if (index === draggedIndex) {
                    newCategories[targetSubcategory] = newPages[targetSubcategory];
                    newCategories[targetSubcategory] = pages[targetSubcategory];
                } else if (index === droppedIndex) {
                    newCategories[category] = newPages[category];
                    newCategories[category] = pages[category];
                } else newCategories[key] = newPages[key];
            });

            setPages(newCategories);
        } else {
            const subcategory = e.dataTransfer.getData("subcategory");

            if (subcategory !== targetSubcategory) {
                const newPages = {...pages};
                const subcategories = newPages[selectedCategory];

                const draggedIndex = Object.keys(subcategories).indexOf(subcategory);
                const droppedIndex = Object.keys(subcategories).indexOf(targetSubcategory);


                const newSubcategories = {};
                Object.keys(subcategories).forEach((key, index) => {
                    if (index === draggedIndex) {
                        newSubcategories[targetSubcategory] = subcategories[key];
                        newSubcategories[targetSubcategory] = pages[selectedCategory][targetSubcategory];
                    } else if (index === droppedIndex) {
                        newSubcategories[subcategory] = subcategories[key];
                        newSubcategories[subcategory] = pages[selectedCategory][subcategory];
                    } else newSubcategories[key] = subcategories[key];
                });

                newPages[selectedCategory] = newSubcategories;
                setPages(newPages);
            }
        }
    }

    useEffect(() => {
        callApi('categories?infos=true').then(data => {
            setPages(data);
            setPagesInit(deepClone(data));
            const categoriesName2 = Object.keys(data);
            setSelectedCategory(categoriesName2[0]);
            console.log(categoriesName2[0]);
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        if (JSON.stringify(pages) !== JSON.stringify(pagesInit)) setSave(false);
        else setSave(true);
    }, [pages])

    if (!loading) return (
        <div className="admin__pages">
            <h1 className="admin__pages__title">Admin Pages</h1>
            <div className="admin__pages__categories">
                { Object.keys(pages).length > 0 && Object.keys(pages).map((categoryName, index) => {
                    return (
                        <div className="admin__pages__categories__item" id={selectedCategory === categoryName ? "selected" : ""} key={index} onClick={() => {
                            setSelectedCategory(categoryName);
                        }}
                             draggable
                             onDragStart={(e) => handleDragStart(e, categoryName, "cat")} key={index}
                             onDragOver={(e) => handleDragOver(e, categoryName)}
                             onDrop={(e) => handleDrop(e, categoryName)}
                        >
                            <h2>{categoryName}</h2>
                        </div>
                    );
                })}
                <div className="admin__pages__categories__item__add" onClick={() => addItem("cat")}>
                    <i className="fa-solid fa-plus"></i>
                </div>
            </div>
            <div className={seeAddSubcat ? "admin__pages__categories__add" : "admin__pages__subcategories"}>
                {!seeAddSubcat &&
                    <>
                        <div className="admin__pages__subcategories__config__buttons">
                            <div className="admin__pages__subcategories__config__buttons__delete" onClick={deleteCat}>
                                <i className="fa-solid fa-trash"></i>
                            </div>
                            <div className="admin__pages__subcategories__config__buttons__edit" onClick={changeNameCat}>
                                <i className="fa-solid fa-cog"></i>
                            </div>
                        </div>
                        { Object.keys(pages).length > 0 && (() => {
                            const subcategories = [];
                            const itemsLength = selectedCategory !== "" ? Object.keys(pages[selectedCategory]).length : 0;
                            for (let i = 0; i < itemsLength; i++) {
                                subcategories.push(Object.keys(pages[selectedCategory])[i]);
                            }
                            if (selectedCategory) {
                                return subcategories.map((subcategory, index) => (
                                    <div className="admin__pages__subcategories__item" draggable
                                         onDragStart={(e) => handleDragStart(e, subcategory)} key={index}
                                         onDragOver={(e) => handleDragOver(e, subcategory)}
                                         onDrop={(e) => handleDrop(e, subcategory)}
                                         onClick={() => {
                                             openEditSubcat(subcategory)
                                         }}>
                                        <h2>{subcategory}</h2>
                                    </div>
                                ));
                            } else {
                                return null;
                            }
                        })()}
                        <div className="admin__pages__categories__subitem__add" onClick={() => {
                            addItem("subcat")
                        }}>
                            <i className="fa-solid fa-plus"></i>
                        </div>
                    </>
                }
                {seeAddSubcat &&
                    <div className="admin__pages__add">
                        <div className="admin__pages__add__subcat">
                            <div className="admin__pages__add__subcat__content__title">
                                <h2>{newSubcat.new ? "Ajouter" : "Modifier"} une sous-catégorie</h2>
                            </div>
                            <div className="admin__pages__add__subcat__content__name">
                                <h3>Nom</h3>
                                <input type="text" placeholder="Nom de la sous-catégorie"
                                       defaultValue={newSubcat.name}
                                       onChange={(e) => {
                                           setNewSubcatElement("name", e.target.value)
                                       }}/>
                            </div>
                            <div className="admin__pages__add__subcat__content__img">
                                <h3>Image</h3>
                                <input type="file" id="input__file" onChange={handleFileChange} accept="image/*"/>
                                <button onClick={() => {
                                    openSelectFile()
                                }}>Choisir une image
                                </button>
                            </div>
                            <div className="admin__pages__add__subcat__content__description">
                                <h3>Description</h3>
                                <textarea placeholder="Description de la sous-catégorie"
                                          defaultValue={newSubcat.description} onChange={(e) => {
                                    setNewSubcatElement("description", e.target.value)
                                }}/>
                            </div>

                            <div className="admin__pages__add__subcat__content__buttons">
                                <button className="validate" onClick={() => addSubcat()}>Valider</button>
                                {newSubcat.new !== true &&
                                    <button className="delete" onClick={() => {
                                        deleteSubcat()
                                    }}>Supprimer
                                    </button>
                                }
                                <button onClick={() => {
                                    setSeeAddSubcat(false)
                                }}>Annuler
                                </button>
                            </div>
                        </div>
                        <div className="admin__pages__add__prev">
                            <div className="admin__pages__add__prev__img">
                                <img src={newSubcat.img} loading="lazy" decoding="async"/>
                            </div>
                            <div className="admin__pages__add__prev__text">
                                <h2>{newSubcat.name}</h2>
                                <button>Voir plus</button>
                                <p>{newSubcat.description}</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {!save &&
                <div className="admin__pages__save" onClick={() => {
                    callApi('categories', 'POST', pages).then(data => {
                        if (data.success) {
                            setPagesInit(deepClone(pages));
                            setSave(true);
                            window.location.reload();
                        }
                    })
                }}>Sauvegarder
                </div>
            }
        </div>
    )
}
export default AdminPages