import React, {useEffect, useState} from "react";
import {callApi, resizeImage} from "../../functions/index.js";
import CardBuy from "../../component/CardBuy.js";

const AdminProducts = () => {
    const [categories, setCategories] = useState([]);
    const [seeAddProduct, setSeeAddProduct] = useState(false);
    const [save, setSave] = useState(false);
    const [indexImage, setIndexImage] = useState(0);
    const [newProduct, setNewProduct] = useState({
        name: "",
        description: "",
        price: "",
        img: {
            0: "https://via.placeholder.com/220x150",
        },
        category: "",
        subcategory: "",
        new: true
    });

    const editProduct = (category, subcategory, product) => {
        setNewProduct({
            name: categories[category][subcategory][product].name,
            description: categories[category][subcategory][product].description,
            price: categories[category][subcategory][product].price,
            img: categories[category][subcategory][product].img,
            category: category,
            subcategory: subcategory,
            id_product: categories[category][subcategory][product].id_product,
            new: categories[category][subcategory][product].name,
        });
        setSeeAddProduct(true);
    }

    const saveCategories = async () => {
        await callApi('categories', "POST", categories);
        setSave(false)
        window.location.reload();
    }

    const addProduct = () => {
        const newCategories = categories;
        console.log(Object.keys(categories[newProduct.category][newProduct.subcategory]));
        let length = Object.keys(categories[newProduct.category][newProduct.subcategory]).length;
        if (Object.keys(categories[newProduct.category][newProduct.subcategory]).includes("img")) length = length - 1;
        if (Object.keys(categories[newProduct.category][newProduct.subcategory]).includes("description")) length = length - 1;
        if (Object.keys(categories[newProduct.category][newProduct.subcategory]).includes("name")) length = length - 1;
        const thisProduct = {
            name: newProduct.name,
            description: newProduct.description,
            price: newProduct.price,
            img: newProduct.img
        }
        if (newProduct.new === true) {
            newCategories[newProduct.category][newProduct.subcategory] = {
                ...categories[newProduct.category][newProduct.subcategory],
                [length]: thisProduct
            };
            setCategories(newCategories);
        } else {
            thisProduct.id_product = newProduct.id_product;
            for (let i = 0; i < Object.keys(newCategories[newProduct.category][newProduct.subcategory]).length; i++) {
                if (newCategories[newProduct.category][newProduct.subcategory][i].name === newProduct.new) {
                    var index = i;
                    break;
                }
            }
            newCategories[newProduct.category][newProduct.subcategory][index] = thisProduct;
        }
        setCategories(newCategories);
        console.log(categories[newProduct.category][newProduct.subcategory]);
        setProductToDefault();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (indexImage === 0) {
            resizeImage(file, 500, 500, (blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    setNewProduct({...newProduct, img: { ...newProduct.img, 0: reader.result}});
                }
            });
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setNewProduct({...newProduct, img: {...newProduct.img, [indexImage]: reader.result}});
            }
        }
    }

    const deleteProduct = () => {
        const newCategories = categories;
        for (let i = 0; i < Object.keys(newCategories[newProduct.category][newProduct.subcategory]).length; i++) {
            if (newCategories[newProduct.category][newProduct.subcategory][i].name === newProduct.new) {
                var index = i;
                break;
            }
        }
        delete newCategories[newProduct.category][newProduct.subcategory][index];
        setCategories(newCategories);
        setProductToDefault();
    }

    const setProductToDefault = () => {
        setNewProduct({
            name: "",
            description: "",
            price: "",
            img: {
                0: "https://via.placeholder.com/220x150",
            },
            category: "",
            subcategory: "",
            new: true
        })
        setSave(true);
        setSeeAddProduct(false);
    }

    useEffect(() => {
        callApi('categories?infos=true').then(response => {
            setCategories(response);
        });
    }, []);

    return (
        <div className="admin__products">
            <h1>Admin Products</h1>
            {!seeAddProduct ?
                <div className="admin__products__container">
                    <div className="admin__products__container__products" id="buy__card">
                        <ul className="cart__card">
                            {Object.keys(categories).map((category, index) => {
                                return (
                                    Object.keys(categories[category]).map((subcategory, index) => {
                                        return (
                                            Object.keys(categories[category][subcategory]).map((product, index) => {
                                                if (categories[category][subcategory][product].name) return (
                                                    <div key={index}>
                                                        <li className="card" onClick={() => {
                                                            editProduct(category, subcategory, product)
                                                        }} style={{cursor: "pointer", height: "350px"}}>
                                                            <img
                                                                src={categories[category][subcategory][product].img[0]?.includes("/images/") ? "https://api.broflex.fr" + categories[category][subcategory][product].img[0] : categories[category][subcategory][product].img[0] || "https://via.placeholder.com/220x150"}
                                                                loading="lazy" decoding="async"
                                                                alt="img"/>
                                                            <h3>{categories[category][subcategory][product].name}</h3>
                                                            <div className="separator"/>
                                                            <p className="price"><i
                                                                className="fa-solid fa-money-bill"></i> {categories[category][subcategory][product].price} €
                                                            </p>
                                                            <p className="more">{categories[category][subcategory][product].description}</p>
                                                        </li>
                                                    </div>
                                                );
                                                return ("")
                                            })
                                        );
                                    })
                                );
                            })}
                            <div className="card" id="card__add" onClick={() => setSeeAddProduct(true)}>
                                <i className="fa-sharp fa-solid fa-plus"/>
                            </div>
                        </ul>
                    </div>
                    <div className="admin__products__container__categories">
                        <h2>Catégories</h2>
                        <ul>
                            {Object.keys(categories).map((category, index) => {
                                return (
                                    Object.keys(categories[category]).map((subcategory, index) => {
                                        return (
                                            <li key={index}>{subcategory}</li>
                                        );
                                    })
                                )
                            })}
                        </ul>
                    </div>
                    {save &&
                        <div className="admin__products__container__save">
                            <button type="button" onClick={saveCategories}>Sauvegarder</button>
                        </div>
                    }
                </div>
                :
                <div className="admin__products__addproduct" id="buy__card">
                    <div className="admin__products__addproduct__container">
                        <h2 className="admin__products__addproduct__title">Ajouter un produit</h2>
                        <div className="admin__products__addproduct__name">
                            <h3>Nom</h3>
                            <input type="text" value={newProduct.name}
                                   onChange={(e) => setNewProduct({...newProduct, name: e.target.value})}/>
                        </div>
                        <div className="admin__products__addproduct__description">
                            <h3>Description</h3>
                            <input type="text" value={newProduct.description}
                                   onChange={(e) => setNewProduct({...newProduct, description: e.target.value})}/>
                        </div>
                        <div className="admin__products__addproduct__price">
                            <h3>Prix</h3>
                            <input type="number" value={newProduct.price}
                                   onChange={(e) => setNewProduct({...newProduct, price: e.target.value})}/>
                        </div>
                        <div className="admin__products__addproduct__category">
                            <h3>Catégorie</h3>
                            <select name="category" placeholder='Choisit la catégorie' id="category"
                                    onChange={(e) => setNewProduct({...newProduct, category: e.target.value})}>
                                <option value="new" disabled selected>Catégorie</option>
                                {Object.keys(categories).map((category, index) => {
                                    return (
                                        <option key={index} value={category}
                                                selected={newProduct.category === category}>{category}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="admin__products__addproduct__category">
                            <h3>Sous-catégorie</h3>
                            <select name="category" placeholder='Choisit la sous-catégorie' id="subcategory"
                                    onChange={(e) => setNewProduct({...newProduct, subcategory: e.target.value})}>
                                <option value="new" disabled selected>Sous-catégorie</option>
                                {Object.keys(categories).map((category, index) => {
                                    if (category === newProduct.category)
                                        return (
                                            Object.keys(categories[category]).map((subcategory, index) => {
                                                return (
                                                    <option key={index} value={subcategory}
                                                            selected={newProduct.subcategory === subcategory}>{subcategory}</option>
                                                );
                                            })
                                        );
                                })}
                            </select>
                        </div>
                        <div className="admin__products__addproduct__radio">
                            <h3>Image</h3>
                            {/* select the index of the image */}
                            <div className="admin__products__addproduct__radio__container">
                                <div className="admin__products__addproduct__radio__container__image">
                                    <input type="radio" id="image1" name="image" value="image1" defaultChecked={true} checked={indexImage === 0}  onClick={() => {setIndexImage(0)}}/>
                                    <label htmlFor="image1">1</label>
                                </div>
                                <div className="admin__products__addproduct__radio__container__image">
                                    <input type="radio" id="image2" name="image" value="image2" checked={indexImage === 1}  onClick={() => {setIndexImage(1)}}/>
                                    <label htmlFor="image2">2</label>
                                </div>
                                <div className="admin__products__addproduct__radio__container__image">
                                    <input type="radio" id="image3" name="image" value="image3" checked={indexImage === 2}  onClick={() => {setIndexImage(2)}}/>
                                    <label htmlFor="image3">3</label>
                                </div>
                                <div className="admin__products__addproduct__radio__container__image">
                                    <input type="radio" id="image4" name="image" value="image4" checked={indexImage === 3} onClick={() => {setIndexImage(3)}}/>
                                    <label htmlFor="image4">4</label>
                                </div>
                            </div>
                        </div>
                        <div className="admin__products__addproduct__image">
                            <input type="file" accept="image/*" id="input__file"
                                   onChange={handleFileChange}/>
                            <button type="button" onClick={() => {
                                document.getElementById("input__file").click()
                            }}>Sélectionne une image
                            </button>
                        </div>
                        <div className="admin__products__addproduct__buttons">
                            <button type="button" className="validate"
                                    onClick={addProduct}>{newProduct.new === true ? "Ajouter" : "Modifier"}</button>
                            {newProduct.new !== true &&
                                <button type="button" className="delete" onClick={deleteProduct}>Supprimer</button>
                            }
                            <button type="button" onClick={setProductToDefault}>Annuler</button>
                        </div>
                    </div>
                    <div className="admin__products__addproduct__preview cart__card">
                        <CardBuy name={newProduct.name} description={newProduct.description} price={newProduct.price}
                                 image={newProduct.img[0]}/>
                    </div>
                </div>
            }
        </div>
    );
}

export default AdminProducts;