import {useEffect, useState} from "react";
import {callApi} from "../../functions/index.js";

export const AdminCommands = () => {
    const [orders, setOrders] = useState({});
    const [filter, setFilter] = useState("");

    useEffect(() => {
        callApi("orders/all?admin=true", "GET").then((response) => {
            if (response.success) setOrders(response.orders);
        })
    }, []);

    const handleChange = (e, checkout_reference) => {
        const status = e.target.value;
        callApi("orders/status", "POST", {checkout_reference: checkout_reference, status: status}).then((response) => {
            if (response.success) {
                window.location.reload();
            }
        })
    }

    const getStatusText = (status) => {
        switch (status) {
            case 0:
                return "En attente de paiement";
            case 1:
                return "En cours de préparation";
            case 2:
                return "En cours de livraison";
            case 3:
                return "Livrée";
            case 4:
                return "Annulée";
            default:
                return "Inconnu";
        }
        return "Inconnu";
    }

    return (
        <div className="commands">
            <div className="commands_container">
                <div className="commands_container__title">
                    <div className="commands_container__title__container">
                        <div className="commands_container__title__container__text">
                            <h2>Commandes</h2>
                            <div className="commands_container__title__container__separator"/>
                        </div>
                    </div>
                    <select className="commands_container__title__container__select" id="status" onChange={(e) => {
                        setFilter(e.target.value);
                    }}>
                        <option value="" disabled selected>Filtrer par status</option>
                        <option value="0">En attente de paiement</option>
                        <option value="1">En cours de préparation</option>
                        <option value="2">En cours de livraison</option>
                        <option value="3">Livrée</option>
                        <option value="4">Annulée</option>
                        <option value="">Toutes</option>
                    </select>
                </div>
                <div className="commands_container__content">
                    <div className="commands_container__content__container">
                        {orders.length > 0 && orders.filter((order) => {
                            if (filter === "") return true;
                            return order.status === parseInt(filter);
                        }).map((order, index) => (
                            <div className="commands_container__content__container__order" key={index}>
                                <div className="commands_container__content__container__order__reference">
                                    <h2 className="commands_container__content__container__order__reference__title">Référence:</h2>
                                    <p className="commands_container__content__container__order__reference__text">{order.checkout_reference}</p>
                                </div>
                                <div className="commands_container__content__container__order__header">
                                    <p className="commands_container__content__container__order__header__title">Commande {index + 1}</p>
                                    <p className="commands_container__content__container__order__header__date">{
                                        //transform timestamp to date
                                        new Date(parseInt(order.date)).toLocaleDateString("fr-FR", {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })
                                    }</p>
                                </div>
                                <div className="commands_container__content__container__order__content">
                                    <div
                                        className="commands_container__content__container__order__content__firstName">
                                        <h2 className="commands_container__content__container__order__content__firstName__title">Prénom:</h2>
                                        <p className="commands_container__content__container__order__content__firstName__text">{order.firstName}</p>
                                    </div>
                                    <div
                                        className="commands_container__content__container__order__content__lastName">
                                        <h2 className="commands_container__content__container__order__content__lastName__title">Nom:</h2>
                                        <p className="commands_container__content__container__order__content__lastName__text">{order.name}</p>
                                    </div>
                                </div>
                                <div className="commands_container__content__container__order__status">
                                    <h2 className="commands_container__content__container__order__status__title">Statut:</h2>
                                    <p className="commands_container__content__container__order__status__text">{getStatusText(order.status)}</p>
                                </div>
                                <div className="commands_container__content__container__order__button">
                                    <button className="commands_container__content__container__order__button__button"
                                            onClick={() => window.location.href = `/orders?checkout_reference=${order.checkout_reference}`}>Voir
                                        la commande
                                    </button>
                                    <select className="commands_container__content__container__order__button__button"
                                            id="status" onChange={(e) => {
                                        handleChange(e, order.checkout_reference)
                                    }}>
                                        <option value="" disabled selected>Changer le status</option>
                                        <option value="0">En attente de paiement</option>
                                        <option value="1">En cours de préparation</option>
                                        <option value="2">En cours de livraison</option>
                                        <option value="3">Livrée</option>
                                        <option value="4">Annulée</option>
                                    </select>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}