import React from 'react';

const CardBuy = ({name, price, description, image = false, setImage, setItemInfo, id_product}) => {

    const openImage = () => {
        const images = [];
        for (let i in image) {
            images.push(image[i]);
        }
        setImage(images);
        setItemInfo([name, price, description, id_product]);
    }

    return (
        <li className="card">
            <div className="image">
                <img loading="lazy" decoding="async"
                     src={image[0]?.includes("/images/") ? "https://api.broflex.fr" + image[0] : image[0]} alt="img"/>
                <div className="overlay">
                    <div onClick={openImage} className="image-button">
                        <i className="fas fa-plus"></i>
                    </div>
                </div>
            </div>
            <h3>{name}</h3>
            <div className="separator"/>
            <p className="price"> {price.includes(",") ? price : price + ",00"} €</p>
            <button className="add__cart__btn"
                    onClick={openImage}>
                <i className="fa-solid fa-shopping-cart"></i> Ajouter au panier
            </button>
        </li>
    );
};

export default CardBuy;