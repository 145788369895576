import React from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../../assets/img/logo.webp";

const AdminSideBarre = () => {
    return (
        <div className="sidebarre">
            <div className="sidebarre__logo">
                <NavLink to="/">
                    <img src={logo} alt="img"/>
                </NavLink>
            </div>
            <div className="sidebarre__separator"/>
            <div className="sidebarre__cats">
                <ul>
                    <NavLink to="/admin"><li><i className="fa-solid fa-gauge"></i>Tableau de board</li></NavLink>
                    <NavLink to="/admin/pages"><li><i className="fa-solid fa-file-lines"></i>Pages</li></NavLink>
                    <NavLink to="/admin/produits"><li><i className="fa-solid fa-cart-plus"></i>Produits</li></NavLink>
                    <NavLink to="/admin/commandes"><li><i className="fa-solid fa-shopping-cart"></i>Commandes</li></NavLink>
                </ul>
            </div>
            <div className="sidebarre__separator__bottom"/>
            <div className="sidebarre__social">
                <ul>
                    <li><i className="fa-brands fa-facebook"></i></li>
                    <li><i className="fa-brands fa-instagram"></i></li>
                </ul>
            </div>
        </div>
    );
};

export default AdminSideBarre;