import React,{useEffect, useState}  from "react";
import {BrowserRouter, Routes as Switch, Route} from "react-router-dom";
import {Toaster} from "sonner";
import {callApi} from "./functions/index.js";
import ScollToTop from "./functions/scrollToTop.js";
import AdminHome from "./pages/Admin/Home.js";
import AdminSideBarre from "./component/utils/Admin/SideBarre.js";
import {AdminCommands} from "./pages/Admin/Commandes.js";
import AdminPages from "./pages/Admin/Pages.js";
import AdminProducts from "./pages/Admin/Products.js";
import { CartProvider } from "./component/CartContext.js";
const Footer = React.lazy(() => import("./component/utils/Footer.js"));
const Category = React.lazy(() => import("./pages/Category.js"));
const Cart = React.lazy(() => import("./pages/Cart.js"));
const Shop = React.lazy(() => import("./pages/Shop.js"));
const Register = React.lazy(() => import("./pages/Register.js"));
const Login = React.lazy(() => import("./pages/Login.js"));
const Profil = React.lazy(() => import("./pages/Profil.js"));
const ResetPwd = React.lazy(() => import("./pages/ResetPwd.js"));
const Contact = React.lazy(() => import("./pages/Contact.js"));
const LegalNotice = React.lazy(() => import("./pages/LegalNotice.js"));
const Home = React.lazy(() => import("./pages/Home.js"));
const Navbar = React.lazy(() => import("./component/utils/Navbar.js"));

function App() {
    const [user, setUser] = useState({});
    const [adminPath, setAdminPath] = useState(false);
    const [categories, setCategories] = useState();
    const [button, setButton] = useState(false);
    // use useRef to verify if the user is on the top of the page

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            callApi('auth/me', 'GET')
                .then((response) => {
                    if (response.id) {
                        setUser(response);
                    } else {
                        localStorage.removeItem('token');
                    }
                })
        }
        const fetchCategories = async () => {
            const response = await callApi("categories");
            setCategories(response);
        }

        window.addEventListener('touchend', (event) => {
            if (!event.target.classList.contains('navbarre__mobile__burger__sub__content__item__title') && !event.target.classList.contains('sub-menu__item')) {
                setButton(false);
            }
        });
        fetchCategories();

        return () => {
            window.removeEventListener('touchend', (event) => {
                if (!event.target.classList.contains('navbarre__mobile__burger__sub__content__item__title') && !event.target.classList.contains('sub-menu__item')) {
                    setButton(false);
                }
            });
        }
    }, []);

    return (
        <div className={user.admin && adminPath ? "main-container admin" : "main-container"}>
            <Toaster position="top-right" expand={true} richColors closeButton duration="3000"/>
            <BrowserRouter>
                <ScollToTop setAdminPath={setAdminPath} setButton={setButton}/>
                <CartProvider>
                    {user.admin && adminPath &&
                        <AdminSideBarre/>
                    }
                    <Navbar user={user} categories={categories} button={button} setButton={setButton}/>
                    <div className="main-container__content">
                        <Switch>
                            <Route path="/" element={<React.Suspense> <Home categories={categories}/> </React.Suspense>}/>
                            <Route path="reset-password" element={<React.Suspense> <ResetPwd/> </React.Suspense>}/>
                            <Route path="/:category/:shop" element={<React.Suspense> <Shop/> </React.Suspense>}/>
                            <Route path="/:category" element={<React.Suspense> <Category/> </React.Suspense>}/>
                            <Route path="/admin" element={<React.Suspense> <AdminHome/> </React.Suspense>}/>
                            <Route path="/contact" element={<React.Suspense> <Contact/> </React.Suspense>}/>
                            <Route path="/admin/pages" element={<React.Suspense> <AdminPages/> </React.Suspense>}/>
                            <Route path="/admin/produits" element={<React.Suspense> <AdminProducts/> </React.Suspense>}/>
                            <Route path="/admin/commandes" element={<React.Suspense> <AdminCommands/> </React.Suspense>}/>
                            <Route path="/panier" element={<React.Suspense> <Cart user={user}/> </React.Suspense>}/>
                            <Route path="/register" element={<React.Suspense> <Register/> </React.Suspense>}/>
                            <Route path="/login" element={<React.Suspense> <Login/> </React.Suspense>}/>
                            <Route path="/mentions-legales" element={<React.Suspense> <LegalNotice/> </React.Suspense>}/>
                            <Route path="/account" element={<React.Suspense> <Profil/> </React.Suspense>}/>
                        </Switch>
                        <div id="bubbles-container">
                        </div>
                    </div>
                    <Footer/>
                </CartProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;


/*
async function sleep(number) {
    return new Promise(resolve => setTimeout(resolve, number));
}

    function createBubble() {
        // Créer une div pour la bulle
        const bubble = document.createElement('div');
        bubble.classList.add('bubble');
        bubble.appendChild(document.createElement('span'));
        bubble.appendChild(document.createElement('span'));
        bubble.appendChild(document.createElement('span'));
        bubble.appendChild(document.createElement('span'));
        bubble.appendChild(document.createElement('span'));

        // Définir la position aléatoire de la bulle
        const left = Math.random() * 95;
        bubble.style.left = `${left}%`;

        // Ajouter la bulle à la div principale
        const container = document.getElementById('bubbles-container');
        container.appendChild(bubble);

        // Supprimer la bulle après un certain temps
        setTimeout(() => {
            bubble.remove();
        }, 7000);
    }

     useEffect(() => {
         // Créer une bulle toutes les 100ms
         const interval = setInterval(async() => {
             createBubble();
             await sleep(1000);
             createBubble();
             await sleep(1000);
             createBubble();
             await sleep(1000);
             createBubble();
             await sleep(1000);
             createBubble();
             await sleep(1000);
             createBubble();
             await sleep(1000);
             createBubble();
         }, 7000);

         // Supprimer l'intervalle lorsque le composant est démonté
         return () => clearInterval(interval);
     }, [])*/